.header {
  background: _var("bg-color");

  @at-root :root {
    --heading-height: 60px;
  }

  --text-color: #000;
  height: _var("heading-height");
  padding: spacing(1, 0);
  position: sticky;
  top: 0;
  transition: height, background-color, top .2s ease-out;
  width: 100%;
  z-index: 10;

  div {
    display: flex;
    height: 100%;
  }

  svg {
    height: 100%;
  }

  .logo {
    color: $primary-color;
    margin-right: auto;
  }

  .title {
    margin: 0;
    color: _var('text-color');
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    display: flex;
    align-self: center;
  }

  .menu {
    background: _var("bg-color");
    display: none;
    flex-direction: column;
    gap: 10px;
    height: calc(100vh - #{_var("heading-height")});
    left: 0;
    padding: spacing();
    position: absolute;
    right: 0;
    top: 100%;
    transition: background-color .5s ease-out;

    a {
      align-items: center;
      color: _var("text-color");
      display: flex;
      font-family: $font-family-secondary;
      font-size: $text-size;
      font-style: normal;
      font-weight: 500;
      height: 50px;
      letter-spacing: 1.5px;
      padding: spacing(0, 1);
      text-decoration: none;
      white-space: nowrap;

      &.with-child {
        display: none;
      }

      &.pay {
        @extend .button, .border;

        margin-top: auto;
      }

      &.contacts {
        @extend .button;
      }

      &:not(.contacts) {
        &.active, &:focus {
          background: #f2f4f9;
        }
      }
    }
  }

  .sub-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  button {
    color: _var("text-color");
    padding: 5px;

    path {
      d: path("M0 2 7 2M7 5 0 5");
      transition: 0.2s;
    }

    &.active path {
      d: path("M0 0l7 7m0-7L0 7");
    }

    &.active + nav {
      display: flex;
    }
  }

  &.sticky {
    box-shadow: 0 4px 8px #00000026;

    --bg-color: #fff;
  }

  &.dark {
    --bg-color: #{$dark-primary-color};
    --text-color: #{$dark-text-color};

    a {
      &.pay, &.contacts {
        @extend .white;
      }

      &:not(.contacts) {
        &.active, &:focus {
          background: $dark-light-color;
        }
      }
    }
  }

  &.transparent {
    --bg-color: transparent;

    position: fixed;

    &:not(.sticky) .sub-menu {
      background: $bg-color;
    }

    &.active {
      --bg-color: #{$bg-color};
    }

    &.sticky {
      --bg-color: #fff;
    }

    .apos-notifications + #root & {
      position: absolute;
      top: 137px;
      z-index: 1;
    }
  }

  &.transparent-dark {
    position: fixed;

    &:not(.sticky, .active) {
      --bg-color: transparent;
      --text-color: #{$dark-text-color};

      .sub-menu {
        box-shadow: none;
      }

      a {
        &.pay, &.contacts {
          @extend .white;
        }

        &:not(.contacts) {
          &.active, &:focus {
            background: $dark-light-color;
          }
        }
      }
    }

    .apos-notifications + #root & {
      position: absolute;
      top: 137px;
      z-index: 1;
    }
  }

  &.primary {
    --bg-color: #{$primary-color};
    --text-color: #fff;

    .logo {
      color: _var('text-color');
    }

    #lg {
      --clr: #{$primary-color};
    }
  }

  @include media-breakpoint-up(md) {
    @at-root :root {
      --heading-height: 90px;
    }

    button {
      display: none;
    }

    .menu {
      transition: none;
      display: flex;
      flex-direction: row;
      gap: 15px;
      height: auto;
      padding: 0;
      position: static;

      a {
        font-size: $text-size-xs;
        padding: 0;
        text-transform: uppercase;

        &:not(.contacts, .pay) {
          border-color: transparent;
          border-style: solid;
          border-width: 0 0 1px 0;

          &:hover, &.active, &:focus {
            background: none;
            border-color: _var("text-color");
          }
        }

        &.with-child {
          display: flex;

          &:hover + .sub-menu {
            display: flex;
          }
        }
      }
    }

    .sub-menu {
      align-items: center;
      background: _var("bg-color");
      border-radius: 8px;
      box-shadow: 0 2px 24px #0000001a;
      display: none;
      gap: 15px;
      padding: 24px 32px;
      position: fixed;
      transform: translateX(-50%);

      &:hover {
        display: flex;
      }

      a {
        height: auto;
        margin: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .menu {
      gap: 40px;

      a.pay {
        margin-right: -25px;
      }
    }

    .title {
      font-size: 38px;
      line-height: 52px;
    }
  }
}

#cart-cta-banner {
  font-family: $font-family-secondary;
  background: #086bff;
  padding: 15px 30px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #fff;
  position: sticky;
  z-index: 10;
  top: 0;

  & ~ header {
    top: 60px
  }

  svg {
    display: inline;
  }

  @include media-breakpoint-up(md) {
    & ~ header {
      top: 45px
    }

    br {
      display: none;
    }

    svg {
      width: 16px;
    }
  }
}

#notification {
  font-family: $font-family-secondary;
  background: #f25c5c;
  padding: 15px 30px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #fff;
  position: sticky;
  z-index: 10;
  top: 0;

  & ~ header {
    top: 45px
  }
}