@import "styles/modules/config";
@import "styles/modules/helpers";

.checkoutInProgress {
  overflow: hidden;
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $bg-color;
  z-index: 1000;
  padding-top: spacing(1);
  overflow: auto;
  flex-direction: column;
  display: flex;

  .header {
    position: relative;
  }

  .back {
    height: 28px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    left: spacing(1);
    font-size: $text-size;
    font-family: $font-family-secondary;
    color: $text-color;

    span {
      display: none;
    }
  }

  .title {
    align-self: center;
    font-size: 30px;
    margin: 40px 0 32px;
  }

  .logo {
    color: $primary-color;
    margin: auto;

    svg {
      margin: auto;
    }
  }

  .container {
    padding-bottom: 80px;
    display: grid;
    gap: 24px;
    margin-bottom: auto;
  }

  @include media-breakpoint-up(md) {
    padding-top: spacing(2);

    .logo svg {
      height: 50px;
    }

    .back {
      height: 50px;

      svg {
        height: 20px;
      }

      span {
        display: block;
      }
    }

    .container {
      grid-template-columns: 300px 1fr;
    }

    .submit {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .title {
      font-size: 55px;
      line-height: 68px;
      margin: 65px 0 72px;
    }

    .container {
      grid-template-columns: 450px 1fr;
      gap: 30px;
    }
  }
}

.amzForm {
  display: grid;
  gap: 24px;

  > * {
    grid-column: 1/-1;
    overflow: hidden;
  }

  :global(.StripeElement) {
    overflow: initial;
  }

  .amzRow {
    grid-column: auto;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: .5fr .5fr;
    column-gap: 18px;
  }
}

.stripeStyles {
  :global(.Label) {
    color: #{$light-color};
    font-family: #{$font-family-secondary};
    font-size: #{$text-size};
    text-transform: uppercase;
    line-height: 16px;
    margin-bottom: 8px;
  }

  :global(.Input) {
    border-radius: 3px;
    border: 1px solid #{$light-color};
    outline: none;
  }

  :global(.Input:focus) {
    box-shadow: none;
    border-color: #{$primary-color};
  }

  :global(.Input--invalid) {
    box-shadow: none;
    border-color: #{$warning-color};
  }

  :global(.Error) {
    font-family: $font-family-secondary;
    color: $warning-color;
    font-size: $text-size-md;
    line-height: 20px;
    letter-spacing: 0.266667px;
    margin-top: 8px;
  }
}