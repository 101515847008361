@import "~normalize.css";

*, *::before, *::after {
  box-sizing: border-box;
  outline-color: $primary-color;
}

body {
  background: var(--bg-color);
  color: $text-color;
  font-family: $font-family-primary;
  font-size: var(--font-size);
  font-weight: normal;
  line-height: $line-height;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-headings;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 32px 0;
}

p {
  margin: 0 0 16px 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover, &:focus {
    opacity: .8;
    outline: none;
  }
}

svg {
  display: block;
}