.logos {
  display: grid;
  margin: 0 auto 50px auto;
  max-width: 950px;
  width: 100%;

  &:last-child {
    margin: 50px auto 0 auto;
  }

  .title {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    text-align: center;
    color: $light-color;
    margin-bottom: 16px;
  }

  div {
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap: 45px;
  }

  img {
    display: block;
    height: 30px;
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    div {
      gap: 16px;
      justify-content: space-around;
    }

    img {
      height: 45px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;

    &:last-child {
      margin-top: 80px;
    }

    .title {
      margin-bottom: 24px;
    }

    img {
      height: 55px;
    }
  }
}

.logos-block {
  overflow: hidden;
  background: #fff;

  .logos {
    margin-bottom: 72px;

    div {
      justify-content: center;
      gap: 16px;
    }
  }

  img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: contain;
    padding: 16px;
    background: #F8F4F2;
  }
}