.banner-v2 {
  display: grid;
  overflow: hidden;
  background: #fff;

  .image {
    display: none;
  }

  h1 {
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    margin: 0;
  }

  h2 {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 18px;
  }

  .container {
    max-width: 1440px;
    background: var(--bg-color);
    min-height: calc(100vh - #{_var("heading-height")});
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    align-items: end;
    padding: 0;

    img {
      width: 100%;
    }

    > *:not(picture, .button) {
      padding: spacing(0, 1);
    }
  }

  .btn {
    grid-column: 1;
    grid-row: 3;
    margin: spacing(0, 1) 40px;
  }

  .img-mobile {
    display: block;
    width: 100%;
    min-height: 0;
    grid-column: 1;
    grid-row: 3;
  }

  .description {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 22px;
    line-height: 145%;
    text-align: center;
    letter-spacing: -0.1px;
    color: $text-color;
    margin: 60px 0;
    padding: spacing(0, 1);
    position: relative;
    max-width: 740px;

    &:before {
      content: '';
      height: 1px;
      background: $primary-color;
      position: absolute;
      top: 6px;
      left: spacing(1);
      right: spacing(1);
    }

    svg {
      position: relative;
      background: #fff;
      margin: auto;

      &:first-child {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-top: 16px;
      }
    }
  }

  .logos:last-child {
    margin: 0 0 72px 0;
  }

  @include media-breakpoint-up(md) {
    h1 {
      grid-column: 1;
      grid-row: 3;
      text-align: left;
      margin-bottom: 16px;
    }

    h2 {
      justify-content: start;
      grid-column: 1;
      grid-row: 2;
    }

    .btn {
      grid-column: 1;
      grid-row: 4;
      justify-self: start;
    }

    .container {
      grid-template-rows: 1fr auto;
      grid-template-columns: .7fr .3fr;
      min-height: 50vh;

      > *:not(picture) {
        z-index: 1;
      }
    }

    .img-mobile {
      grid-column: 1/span 2;
      grid-row: 1/span 4;
      align-self: end;
      justify-self: end;
      width: 70%;
    }
  }

  @include media-breakpoint-up(lg) {
    .img-mobile {
      width: 60%;
    }

    h1 {
      font-size: 55px;
      line-height: 68px;
      margin-bottom: 32px;
    }

    h2 {
      margin-bottom: 24px;
      font-size: 24px;

      svg {
        width: auto;
        height: 45px;
      }
    }

    .btn {
      margin-bottom: 72px;
    }

    .description {
      margin: 72px auto 96px auto;
    }

    .container {
      min-height: 650px;
    }

    .logos:last-child {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-breakpoint-up(xl) {
    .container {
      grid-template-columns: 1fr 120px 700px 500px 120px 1fr;
      max-width: initial;
    }

    h1 {
      grid-column: 3;
    }

    h2 {
      grid-column: 3;
    }

    .btn {
      grid-column: 3;
    }

    .img-mobile {
      grid-column: 3/span 3;
      width: 60%;
    }
  }
}