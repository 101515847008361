:root {
  --padding: 15px;
  --bg-color: #{$bg-color};
  --font-size: #{$text-size};

  @include media-breakpoint-up(md) {
    --padding: 20px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body, html, #root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;

  > .footer {
    margin-top: auto;
  }
}

.container {
  margin: 0 auto;
  max-width: $container-width;
  padding: spacing(0, 1);
  width: 100%;
}
