.banner-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  text-align: center;
  overflow: hidden;

  br {
    display: none;

    &.keep {
      display: initial;
    }
  }

  h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 32px;
    text-overflow: ellipsis;
    width: 100%;

    span {
      text-align: center;
    }

    .animated {
      display: inline-grid;
      grid-template-areas: "a";
      justify-content: center;
      max-width: 100%;
      overflow: hidden;
      padding: 5px;
      text-align: left;

      span {
        color: $primary-color;
        display: block;
        grid-area: a;
        opacity: 0;
        position: relative;
        top: 100%;
        transition: top .5s ease-out;
        white-space: normal;

        &::before, &::after {
          content: "\00a0";
        }

        &.current {
          opacity: 1;
          top: 0;
        }

        &.prev {
          opacity: 1;
          top: -120%;
        }

        &.next {
          opacity: 1;
        }
      }
    }
  }

  p {
    color: $light-color;
    font-family: $font-family-secondary;
    font-size: 18px;
    margin: 0 0 32px 0;
    max-width: breakpoint-min(sm);
  }

  .button + .button {
    margin-left: 15px;
  }

  .image {
    margin-bottom: 50px;
    width: 100%;
  }

  img {
    margin: 0 0 -32px 0;
    max-width: 100%;
    object-fit: cover;
    //min-height: 380px;
    z-index: 1;
  }

  &.white {
    background: #fff;
  }

  @media (min-width: 425px) {
    h1 {
      font-size: 30px;
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 40px;
      //white-space: nowrap;
    }

    br {
      display: initial;
    }

    p {
      max-width: initial;
    }

    img {
      margin-bottom: -55px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 30px;

    h1 {
      font-size: 55px;
      line-height: 68px;
      margin-bottom: 48px;
    }

    p {
      font-size: 22px;
    }

    .image {
      margin-bottom: 72px;
    }

    img {
      margin-bottom: -62px;
    }
  }
}
