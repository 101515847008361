@import "modules/config";
@import "modules/helpers";

@import "modules/normalize";
@import "modules/general";
@import "modules/buttons";

@import "modules/header";
@import "modules/hero-v2";
@import "modules/hero";
@import "modules/banner";
@import "modules/banner-v2";

@import "modules/logos";

.black-friday {
  --clr: #fc9d0e;
  --bg-offset: 20px;

  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: #00000099;
  backdrop-filter: blur(10px);

  &.open {
    display: flex;
  }

  button {
    position: absolute;
    right: 8px;
    top: 8px;
    background: #fff;
    border-radius: 50%;
    color: $text-color;
    padding: 2px;

    svg {
      width: 16px;
    }
  }

  .content {
    gap: 10px;
    width: 100%;
    max-width: 600px;
    padding: 24px 16px;
    background: url("images/black-friday.webp") repeat-x 50% calc(50% - var(--bg-offset)), linear-gradient(0deg, #15141a 0%, #15141a 50%, #FC9D0E 50%);
    background-size: 110% auto;
    color: #fff;
    display: grid;
    text-align: center;
    justify-items: center;
    font-feature-settings: 'clig' off, 'liga' off;
    position: relative;
  }

  * {
    margin: 0;
  }

  p {
    font-family: $font-family-secondary;
    font-size: 20px;
    font-weight: 500;
    line-height: 135%;
    margin-bottom: 70px;
  }

  h2 {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    line-height: 115%;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }

  svg {
    height: auto;
  }

  h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 115%;
    margin-bottom: 5px;
  }

  small {
    font-family: $font-family-secondary;
    font-size: 16px;
    font-weight: 500;
    line-height: 135%;
  }

  a {
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px;
  }

  code {
    color: $text-color;
    border-radius: 2px;
    background: $primary-color;
    padding: 4px;
    font-weight: 700;
    line-height: 1.5;
  }

  .timer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $font-family-secondary;

    time {
      font-size: 20px;
      background: #fff;
      color: $text-color;
      padding: 4px;
      border-radius: 2px;
      width: 40px;
      line-height: 40px;
    }
  }

  @include media-breakpoint-up(md) {
    .content {
      padding: 30px 25px;
    }

    h2 {
      font-size: 48px;
    }

    svg {
      width: 50px;
    }

    p {
      margin-bottom: 100px;
      font-size: 20px;

      br {
        display: none;
      }
    }

    h3 {
      font-size: 38px;
    }

    small {
      font-size: 16px;
    }
  }
}