.hero-v2 {
  display: grid;
  align-items: center;
  justify-items: center;
  background: #fff;

  .image {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    object-fit: cover;
    max-height: 750px;
    min-height: 700px;
  }

  .button {
    width: 100%;
  }

  .container {
    margin-bottom: 40px;
    align-self: end;
    z-index: 1;
  }

  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    color: #fff;
    margin-bottom: 24px;
  }

  :where(.image, .container) {
    grid-column: 1;
    grid-row: 1;
  }

  .description {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 22px;
    line-height: 145%;
    text-align: center;
    letter-spacing: -0.1px;
    color: $text-color;
    margin: 60px 0;
    padding: spacing(0, 1);
    position: relative;
    max-width: 740px;

    &:before {
      content: '';
      height: 1px;
      background: $primary-color;
      position: absolute;
      top: 6px;
      left: spacing(1);
      right: spacing(1);
    }

    svg {
      position: relative;
      background: #fff;
      margin: auto;

      &:first-child {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-top: 16px;
      }
    }
  }

  .logos:last-child {
    margin: 0 0 72px 0;
  }

  @include media-breakpoint-up(md) {
    .container {
      margin-bottom: 72px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 32px;
    }

    h1 {
      flex: 1;
      text-align: left;
      margin: 0;
    }

    .button {
      flex: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      max-width: 720px;
      font-size: 55px;
      line-height: 68px;
      margin-right: auto;
    }

    .description {
      margin: 72px auto 96px auto;
    }
  }
}