.hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 30px auto 0 auto;
  padding: spacing(0, 1);
  text-align: center;
  width: 100%;

  h1 {
    font-size: 32px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    span {
      font-size: 24px;
      text-align: center;
    }

    > span {
      display: inline-grid;
      grid-template-areas: "a";
      justify-content: center;
      max-width: 100%;
      overflow: hidden;
      text-align: left;

      span {
        color: $primary-color;
        display: block;
        grid-area: a;
        opacity: 0;
        position: relative;
        top: 100%;
        transition: top .5s ease-out;
        white-space: nowrap;

        &::before, &::after {
          content: "\00a0";
        }

        &.current {
          opacity: 1;
          top: 0;
        }

        &.prev {
          opacity: 1;
          top: -100%;
        }

        &.next {
          opacity: 1;
        }
      }
    }
  }

  p {
    color: $light-color;
    font-family: $font-family-secondary;
    font-size: 18px;
    margin: 0 0 32px 0;
    max-width: breakpoint-min(sm);

    br {
      display: none;
    }
  }

  .button:last-child {
    margin-bottom: 72px;
  }

  .button + .button {
    margin-left: 15px;
  }

  img {
    margin: 32px 0 -18px 0;
    max-height: 230px;
    max-width: 100%;
    padding: 0 16px;
    z-index: 1;
  }

  @media (min-width: 425px) {
    h1 span {
      font-size: inherit;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 72px;

    h1 {
      font-size: 40px;
      white-space: nowrap;
    }

    p {
      max-width: initial;

      br {
        display: initial;
      }
    }

    img {
      margin-bottom: -32px;
      max-height: 420px;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 55px;
      line-height: 68px;
      margin-bottom: 48px;
    }

    p {
      font-size: 22px;
    }

    .button:last-child {
      margin-bottom: 130px;
    }

    img {
      margin-bottom: -42px;
      max-height: 560px;
      max-width: $container-width-sm;
    }

    &.home {
      h1 {
        font-size: 80px;
        line-height: 100px;
      }
    }
  }
}
