.button {
  --bg-color: #000;
  --tx-color: #fff;

  align-items: center;
  background: _var("bg-color");
  border: 1px solid _var("bg-color");
  border-radius: 3px;
  color: _var("tx-color");
  cursor: pointer;
  gap: 8px;
  display: inline-flex;
  font-family: $font-family-secondary;
  font-size: $text-size-xs;
  font-weight: 500;
  justify-content: center;
  line-height: 50px;
  padding: 0 15px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 1.8px;

  &[disabled] {
    --bg-color: #{$light-color};
    pointer-events: none;
  }

  &.border {
    background: none;
    color: _var("bg-color");
  }

  &.white {
    --bg-color: #fff;
    --tx-color: #000;
  }

  &.blue{
    --bg-color: #086BFF;
    --tx-color: #fff;
  }

  &.loading::after {
    background: url("images/loader.svg");
    background-size: cover;
    content: "";
    width: 14px;
    height: 14px;
  }

  &:focus, &:hover {
    opacity: .7;
  }

  @include media-breakpoint-up(md) {
    &.loading::after {
      width: 16px;
      height: 16px;
    }
  }
}